import { useState } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import {
    Box,
    Divider,
    Typography,
    Stack,
    MenuItem,
    Link as MuiLink,
    ListItem,
    List,
    ListItemButton,
    ListItemText,
    Badge,
} from "@mui/material";
// components
import { CustomAvatar } from "@/Components/custom-avatar";
import MenuPopover from "@/Components/menu-popover";
import { IconButtonAnimate } from "@/Components/animate";
import { Link, router, usePage } from "@inertiajs/react";
import Iconify from "@/Components/iconify/Iconify";
import { useEffect } from "react";
import React from "react";

// ----------------------------------------------------------------------

const OPTIONS = [
    {
        label: "Profile",
        link: route("user.profile"),
    },
    {
        label: "Settings",
        link: route("user.profile"),
    },
];

// ----------------------------------------------------------------------

export default function AccountPopover({ notifications }) {
    const {
        auth: { user },
    } = usePage().props;
    const [openPopover, setOpenPopover] = useState(null);
    const [openInbox, setOpenInbox] = useState(null);
    const [openNotif, setOpenNotif] = useState(null);
    const media =
        user.media && user.media[0]
            ? user.media[0].original_url.replace(/^https?:\/\/localhost\//, "/")
            : user.gender != "M"
            ? "/assets/images/default-women.png"
            : "/assets/images/default-men.png";

    notifications?.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );

    const unreadNotifications = notifications?.filter(
        (notification) => notification.read === 0
    );
    const unreadCount = unreadNotifications?.length;

    const handleOpenPopover = (event) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
    };

    const handleOpenInbox = (event) => {
        // setOpenInbox(event.currentTarget);
        router.visit(route("messaging.inbox"));
    };

    const handleCloseInbox = () => {
        setOpenInbox(null);
    };

    const handleOpenNotif = (event) => {
        setOpenNotif(event.currentTarget);
    };

    const handleCloseNotif = () => {
        setOpenNotif(null);
    };

    const handleNotification = (type, from_user_id) => {
        const pageRoute =
            type === "message_update" ? "messaging.conversation.show" : "";
        router.visit(route(pageRoute, from_user_id));
        handleCloseNotif();
    };

    const handleLogout = () => {
        handleClosePopover();
        router.post(route("logout"));
    };
    return (
        <>
            <Stack direction="row" alignItems="center" spacing={1}>
                <Stack spacing={2} direction={"row"} alignItems={"center"}>
                    {/* <Box
                        onClick={handleOpenInbox}
                        style={{ position: "relative", cursor: "pointer" }}
                    >
                        <Iconify
                            icon={"mdi:chat-processing"}
                            sx={{ color: "#001c4e", mt: 0.5 }}
                        />
                    </Box>
                    <Box style={{ position: "relative", cursor: "pointer" }}>
                        <IconButtonAnimate
                            onClick={handleOpenNotif}
                            sx={{
                                p: 0,
                                ...(openPopover && {
                                    "&:before": {
                                        zIndex: 1,
                                        content: "''",
                                        width: "100%",
                                        height: "100%",
                                        position: "absolute",
                                        bgcolor: (theme) =>
                                            alpha(theme.palette.grey[900]),
                                    },
                                }),
                            }}
                        >
                            <Badge badgeContent={unreadCount} color="secondary">
                                <Iconify
                                    icon={"clarity:notification-solid"}
                                    sx={{ color: "#001c4e", mt: 0.5 }}
                                />
                            </Badge>
                        </IconButtonAnimate>
                    </Box> */}
                    <IconButtonAnimate
                        onClick={handleOpenPopover}
                        sx={{
                            p: 0,
                            ...(openPopover && {
                                "&:before": {
                                    zIndex: 1,
                                    content: "''",
                                    width: "100%",
                                    height: "100%",
                                    position: "absolute",
                                    bgcolor: (theme) =>
                                        alpha(theme.palette.grey[900]),
                                },
                            }),
                        }}
                    >
                        <CustomAvatar
                            src={media}
                            name={user?.fullname}
                            alt={user?.fullname}
                        />
                        <Iconify icon="mdi:chevron-down" />
                    </IconButtonAnimate>
                </Stack>
            </Stack>

            {openInbox && (
                <MenuPopover
                    open={openInbox}
                    onClose={handleCloseInbox}
                    sx={{ width: 300, ml: 2 }}
                >
                    <Stack
                        direction={"row"}
                        sx={{ m: 1 }}
                        justifyContent={"space-between"}
                    >
                        <Typography variant="body2">Messages</Typography>
                        <Iconify
                            icon="streamline:interface-setting-slider-horizontal-adjustment-adjust-controls-fader-horizontal-settings-slider"
                            sx={{
                                width: "12px",
                                cursor: "pointer",
                                "&:hover": {
                                    backgroundColor: "rgb(204, 204, 204,0.5)",
                                },
                            }}
                        />
                    </Stack>
                    <MenuItem sx={{ m: 1, mt: 2, position: "relative" }}>
                        <Stack>
                            <CustomAvatar
                                src={media}
                                name={user?.fullname}
                                alt={user?.fullname}
                            />
                        </Stack>
                        <Stack>
                            <Typography
                                variant="body2"
                                sx={{ ml: 2, fontWeight: "bold" }}
                            >
                                {user?.fullname}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{ ml: 2, fontSize: "12px" }}
                            >
                                {user?.fullname}
                            </Typography>
                        </Stack>
                        <Box
                            style={{
                                position: "absolute",
                                left: 250,
                                right: "0",
                                top: "50%",
                                transform: "translateY(-50%)",
                                width: "6px",
                                height: "6px",
                                borderRadius: "50%",
                                backgroundColor: "red", // Change the dot color as needed
                            }}
                        />
                    </MenuItem>
                    <Divider />
                    <Stack sx={{ alignItems: "center", color: "#001c4e" }}>
                        <Link
                            href={route("messaging.inbox")}
                            style={{
                                fontWeight: 600,
                                "&:hover": {
                                    textDecoration: "underline",
                                },
                            }}
                        >
                            Go to inbox
                        </Link>
                    </Stack>
                </MenuPopover>
            )}

            {openNotif && (
                <MenuPopover
                    open={openNotif}
                    onClose={handleCloseNotif}
                    sx={{ width: 300, ml: 2 }}
                >
                    <List
                        sx={{ height: 300, overflowX: "auto" }}
                        component="nav"
                    >
                        {unreadNotifications?.map((notification, key) => (
                            <React.Fragment key={key}>
                                <ListItemButton
                                    onClick={() =>
                                        handleNotification(
                                            notification.type,
                                            notification.from_user_id
                                        )
                                    }
                                >
                                    {/* <ListItemText
                                        primary={notification.message}
                                        style={{ fontWeight: "bold" }}
                                    /> */}
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontWeight: notification.read
                                                ? ""
                                                : "bold",
                                        }}
                                    >
                                        {notification.message}
                                    </Typography>
                                </ListItemButton>
                                <Divider />
                            </React.Fragment>
                        ))}
                        {/* Add more notifications and dividers as needed */}
                    </List>
                </MenuPopover>
            )}

            {openPopover && (
                <MenuPopover
                    open={openPopover}
                    onClose={handleClosePopover}
                    sx={{ width: 200, p: 0, mt: 1.5 }}
                >
                    <Box sx={{ my: 1.5, px: 2.5 }}>
                        <Typography
                            textAlign="center"
                            variant="subtitle2"
                            noWrap
                        >
                            {user.role != "user"
                                ? user?.company?.name
                                : user?.name}
                        </Typography>

                        <Typography
                            textAlign="center"
                            variant="body2"
                            sx={{ color: "text.secondary" }}
                            noWrap
                        >
                            {user?.email}
                        </Typography>
                    </Box>

                    <Divider sx={{ borderStyle: "dashed" }} />

                    <Stack sx={{ p: 1 }}>
                        {OPTIONS.map((option, key) => {
                            // let link;
                            // if (user.role === "user") {
                            //     if (user?.status != "created") {
                            //         link = option.linkTo;
                            //     } else {
                            //         link = router.get(
                            //             route("complete-profile")
                            //         );
                            //     }
                            // } else {
                            //     link = option.linkTo;
                            // }

                            return (
                                <MuiLink
                                    component={Link}
                                    underline="none"
                                    color="text.primary"
                                    href={option.link}
                                    key={key}
                                >
                                    <MenuItem>{option.label}</MenuItem>
                                </MuiLink>
                            );
                        })}
                    </Stack>
                    <Divider sx={{ borderStyle: "dashed" }} />

                    <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
                        Logout
                    </MenuItem>
                </MenuPopover>
            )}
        </>
    );
}
